<template>
  <div id="export_data">
    <b-overlay
      :show="loading"
      rounded="lg"
      :style="'padding: 5px;'"
      variant="white"
      :spinner-small="true"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <div
        v-if="lastDemand && !isLastDemandFinished"
        class="export_data-demande in-progress"
      >
        <div>
          La demande d'export de vos données personnelles du 
          <i>{{ new Date(lastDemand.creation_date).toLocaleDateString() }}</i> à 
          <i>{{ new Date(lastDemand.creation_date).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' }) }}</i> 
          est en attente.
        </div>
      </div>
      <div
        v-else-if="lastDemand && isLastDemandFinished"
        class="export_data-demande"
      >
        <div>
          La demande d'export de vos données personnelles du 
          <i>{{ new Date(lastDemand.creation_date).toLocaleDateString() }}</i> à 
          <i>{{ new Date(lastDemand.creation_date).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' }) }}</i> 
          a aboutie.
        </div>
        <div style="display: flex; justify-content: center;">
          <b-button
            variant="success"
            @click="downloadPersonalData"
          >
            Télécharger
          </b-button>
        </div>
        
      </div>

      <b-button
        id="export_data-button"
        variant="primary"
        :disabled="!isLastDemandFinished"
        @click="demandPersonalData"
      >
        {{ $t('profile.personalDataExport.newDemand') }}
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import personalDataAPI from '@/api/personalDataAPI.js';
import { downloadFile } from '@/utils';

export default {
  name: 'PersonalDataExport',

  data() {
    return {
      loading: false,
      lastDemand: null
    };
  },

  computed: {
    isLastDemandFinished() {
      if (this.lastDemand && !this.lastDemand.stop_date) {
        return false;
      }
      return true;
    }
  },

  created() {
    this.getDemands();
  },

  methods: {
    async demandPersonalData() {
      try {
        this.loading = true;
        const newDemand = await personalDataAPI.postPersonalDataDemand();
        this.lastDemand = newDemand;
        this.loading = false;
        await this.getDemands();
      } catch {
        this.loading = false;
      }
    },

    async getDemands() {
      try {
        this.loading = true;
        const demands = await personalDataAPI.getPersonalDataDemands();
        if (demands && demands.results && demands.results.length) {
          this.lastDemand = demands.results[0];
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async downloadPersonalData() {
      try {
        if (this.lastDemand && this.lastDemand.download_href) {
          this.loading = true;
          const link = document.createElement('a');
          link.href = this.lastDemand.download_href;
          link.setAttribute('download', `personal_data-${this.lastDemand.id}`);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="less">
#export_data {
  width: 100%;
  .export_data-demande {
    margin: 0 2px 1.5rem;
    padding: 0.5rem 0.75rem;
    background-color: #effff4;
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.08);
    color: #495057;
    font-weight: 600;
    text-align: center;
    button {
      margin: 0.5rem;
      font-weight: 600;
    }
  }
  .export_data-demande.in-progress {
    background-color: #fdf6e4;
  }
  #export_data-button {
    width: 100%;
    font-size: 1rem;
    border: 2px solid #9BD0FF;
    border-radius: 8px;
    font-weight: 600;
  }
}
</style>
